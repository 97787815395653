import React from 'react';
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import { graphql, Link } from 'gatsby';
import visuallyAppealingImg from '../assets/images/features/Visually-Appealing.webp';

export default function Template(props) {
  const post = props.pageContext;
  let postHTML = post.html;

  // Replace image-right with appropriate span and width
  if (postHTML.includes("image-right")) {
    postHTML = postHTML.replace(/{image-right.*?width::(\d+).*?}/g, `<span style='width:$1px' class='test-img'>`);
    postHTML = postHTML.replace(/{:image-right}/g, "</span>");
  }

  // Replace image-left with appropriate span and width
  if (postHTML.includes("image-left")) {
    postHTML = postHTML.replace(/{image-left.*?width::(\d+).*?}/g, `<span style='width:$1px' class='test-img'>`);
    postHTML = postHTML.replace(/{:image-left}/g, "</span>");
  }

  return (
    <Layout location="" env={props.data.site.siteMetadata.env}>
      <Navbar />
      <div className="blog-post bg-nav">
        <div className="container">
          <SEO 
            postTitle={post.frontmatter.metaTitle + " | BlueMail App"}
            postDescription={post.frontmatter.metaDescription}
            postImage={post.frontmatter.ogImage ? post.frontmatter.ogImage : post.frontmatter.image}
            postURL={`/blog/${post.frontmatter.path}`} // Adjusted the path to include leading slash
          />
          {post.frontmatter.publishToProd !== false && ( // Ensure it's published
            <div className='col-12'>
              {/* Here is where the image is rendered */}
              {post.frontmatter.image && (
                <img src={post.frontmatter.image} style={{ width: '100%' }} alt={`BlueMail ${post.frontmatter.blogTitle}`} />
              )}
              <div className='blog-content' style={{ marginTop: '45px' }}>
                <h2>{post.frontmatter.blogTitle}</h2>
                {/* Uncomment moment if you're using it for date formatting */}
                {/* {moment(post.frontmatter.date).format('ll')} */}
                <div id="post-html" className='posta' dangerouslySetInnerHTML={{ __html: postHTML }} />
              </div>
            </div>
          )}
          {post.frontmatter.publishToProd === false && ( // If not published
            <div className="container pt-120 pb-100">
              <div className="not-found-area">
                <div>
                  <img style={{ maxWidth: "100%" }} src={visuallyAppealingImg} alt="Visually Appealing" />
                </div>
                <div style={{ padding: '0 30px' }}>
                  <h1 className="mb-20" style={{ fontSize: '3rem', fontWeight: 'normal' }}>No blog found!</h1>
                  <h1 className="mb-30" style={{ fontSize: '2rem', fontWeight: 'normal' }}>Please check Blog Pages.</h1>
                  <Link to='/blog' className='default-btn'>Back to Blog Pages</Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query TemplatePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`;
